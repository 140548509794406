import styled, { css } from 'styled-components';

import { rem } from '@savgroup-front-common/core/src/helpers';
import { APP_COMPONENT_STATUSES } from '@savgroup-front-common/types';

const profileInfoSpanMixin = css<{ $isNewUiEnabled?: boolean }>`
  white-space: nowrap;
  padding: 0 1rem;
  color: ${({ theme, $isNewUiEnabled }) =>
    $isNewUiEnabled ? theme.colors.mainTextColor : theme.colors.white};
  line-height: 1rem;
  &:first-child {
    padding-top: 0.5rem;
  }
  &:last-child {
    padding-bottom: 0.25rem;
  }
`;

export const $MenuTitle = styled.div<{ $isNewUiEnabled?: boolean }>`
  ${profileInfoSpanMixin};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  font-size: 1rem;
  padding-bottom: 0;
  line-height: 1.4rem;
`;

export const $ProfileRole = styled.div<{ $isNewUiEnabled?: boolean }>`
  ${profileInfoSpanMixin};
  font-size: 0.75rem;
  padding-top: 0;
  &:last-child {
    padding-bottom: 0.75rem;
    border-bottom: 1px solid #666;
  }
`;
export const $StatusContainer = styled.div<{ $isNewUiEnabled?: boolean }>`
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.25rem;
  padding-top: 0.5rem;
  &:last-child {
    padding-bottom: 0.75rem;
    border-bottom: 1px solid #666;
  }
  white-space: nowrap;
  color: ${({ theme, $isNewUiEnabled }) =>
    $isNewUiEnabled ? theme.colors.mainTextColor : theme.colors.white};
  display: flex;
  align-items: center;
  font-size: 0.75rem;
`;

export const $StatusLabel = styled.div<{ $isNewUiEnabled?: boolean }>`
  color: ${({ theme, $isNewUiEnabled }) =>
    $isNewUiEnabled ? theme.colors.mainTextColor : theme.colors.white};
  font-size: ${rem(10)};
  margin-left: 0.25rem;
  line-height: 1;
`;

export const $StatusIndicator = styled.div<{
  $status: APP_COMPONENT_STATUSES;
  $hasScheduledMaintenance: boolean;
}>`
  height: ${rem(10)};
  width: ${rem(10)};
  border-radius: 50%;
  margin-right: 0.25rem;

  background: ${({ $status, theme, $hasScheduledMaintenance }) => {
    switch ($status) {
      case APP_COMPONENT_STATUSES.DEGRADED_PERFORMANCE:
        return theme.colors.purple;
      case APP_COMPONENT_STATUSES.PARTIAL_OUTAGE:
        return theme.colors.alert;
      case APP_COMPONENT_STATUSES.MAJOR_OUTAGE:
        return theme.colors.danger;
      case APP_COMPONENT_STATUSES.OPERATIONAL:
        if ($hasScheduledMaintenance) {
          return theme.colors.primary;
        }

        return theme.colors.success;
      case APP_COMPONENT_STATUSES.UNDER_MAINTENANCE:
        return theme.colors.primary;

      default:
        return theme.colors.disabled;
    }

    return theme.colors.success;
  }};
`;

export const $OtherRoles = styled.span`
  text-decoration: underline;
`;

export const $SelectSellerMenu = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  align-items: center;
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  & svg {
    color: ${({ theme }) => theme.colors.primary};
  }
  padding: 0 0 0 8px;
`;

export const $HeaderProfileMenu = styled.div`
  min-width: 275px;
`;
