import React from 'react';
import { createRoot } from 'react-dom/client';
import 'react-tippy/dist/tippy.css';

import { appShell } from '@savgroup-front-common/core/src/helpers';
import { generateUserManager } from '@savgroup-front-common/core/src/services/userManager';
import { setStoreAndHistory } from 'control/view/app/App.config';

import '../datadog.config';

import AuthConfig from './configuration/auth/Auth.config';
import './helpers/localMoment';
import App from './view/app/App';
import './view/wdyr';

if (![].at) {
  Array.prototype.at = function (pos) {
    return this.slice(pos, pos + 1)[0];
  };
}

const userManager = generateUserManager(AuthConfig);

setStoreAndHistory({ userManager });

appShell({
  userManager,
  render: () => {
    const container = document.getElementById('root');
    const root = createRoot(container);

    root.render(<App />);
  },
});
