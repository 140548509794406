import React, { FunctionComponent, useState } from 'react';

import { LANGUAGES } from '@savgroup-front-common/constants/src/shared';
import {
  Menu,
  MenuItem,
  VERTICAL_MENU_ITEM_TYPES,
  VERTICAL_MENU_POSITIONS,
} from '@savgroup-front-common/core/src/atoms/Menu';
import { MENU_ITEM_TYPES } from '@savgroup-front-common/core/src/atoms/Menu/Menu.types';
import { useNewUiContext } from '@savgroup-front-common/core/src/components/NewUiProvider/NewUiProvider';
import { selectSellers } from '@savgroup-front-common/core/src/domains/sellers/selectors';
import { sortBy } from '@savgroup-front-common/core/src/helpers';
import {
  DarkModeIcon,
  KeyboardIcon,
  LanguageIcon,
  LightModeIcon,
  LogoutIcon,
  RadioCheckedIcon,
  RadioUncheckedIcon,
} from '@savgroup-front-common/core/src/protons/icons';
import UserGroupIcon from '@savgroup-front-common/core/src/protons/icons/UserGroup.icon';

import { useControlTypedSelector } from '../../../../../hooks';
import { useAppContext } from '../../../../app/App.context';
import { useShortcutsModal } from '../hooks';
import ShortcutsModal from '../ShortcutsModal';

import HeaderProfileAppStatus from './HeaderProfileAppStatus';
import { $HeaderProfileMenu } from './HeaderProfileMenu.styles';
import HeaderProfileUserName from './HeaderProfileUserName';
import { useProfileActions } from './hooks';
import { useProfileData } from './hooks/useProfileData';
import messages from './messages';

interface HeaderProfileMenuProps {
  onClose: () => void;
  isOpen: boolean;
  wrapperRef: React.RefObject<HTMLDivElement>;
}

const HeaderProfileMenu: FunctionComponent<
  React.PropsWithChildren<HeaderProfileMenuProps>
> = ({ wrapperRef, onClose, isOpen }) => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleCloseShortcutsModal,
    isShortcutsModalOpen,
    onOpenShortcutModal,
  } = useShortcutsModal();
  const { selectedSeller, onSelectSeller } = useAppContext();

  const { languages, isLoaded, locale } = useProfileData();
  const { updateIntl, logout } = useProfileActions();
  const sellers = useControlTypedSelector(selectSellers);

  const { isDarkModeEnabled, onToggleDarkMode } = useNewUiContext();

  const items: MenuItem[] = [
    {
      type: VERTICAL_MENU_ITEM_TYPES.CUSTOM,
      key: 'name',
      children: (
        <$HeaderProfileMenu>
          <HeaderProfileUserName />
        </$HeaderProfileMenu>
      ),
    },
    {
      type: VERTICAL_MENU_ITEM_TYPES.CUSTOM,
      key: 'appStatus',
      children: <HeaderProfileAppStatus />,
    },
  ];

  if (languages.length > 1) {
    const languageSubmenu = languages.map<MenuItem>((language) => {
      const upperLanguage = language.toUpperCase() as keyof typeof LANGUAGES;
      const key = LANGUAGES[upperLanguage];

      const isCurrent = locale === key;

      return {
        key,
        label: messages[language],
        type: VERTICAL_MENU_ITEM_TYPES.BUTTON,
        onClick: () => updateIntl(key),
        icon: isCurrent ? <RadioCheckedIcon /> : <RadioUncheckedIcon />,
      };
    });

    items.push({
      key: 'language',
      label: messages.languageLabel,
      type: VERTICAL_MENU_ITEM_TYPES.MENU,
      items: languageSubmenu,
      isDisabled: !isLoaded,
      icon: <LanguageIcon />,
    });
  }

  if (sellers.length > 1) {
    items.push({
      key: 'seller',
      label: messages.selectSeller,
      type: VERTICAL_MENU_ITEM_TYPES.MENU,
      icon: <UserGroupIcon />,
      isSearchable: true,
      items: sellers.sort(sortBy({ fieldName: 'name' })).map((seller) => {
        return {
          key: seller.id,
          label: seller.name,
          type: VERTICAL_MENU_ITEM_TYPES.BUTTON,
          onClick: () => {
            onSelectSeller(seller.id);
            onClose();
          },
          icon:
            selectedSeller?.id === seller.id ? (
              <RadioCheckedIcon />
            ) : (
              <RadioUncheckedIcon />
            ),
        };
      }),
    });
  }

  items.push({
    label: isDarkModeEnabled ? messages.lightmode : messages.darkmode,
    type: MENU_ITEM_TYPES.BUTTON,
    icon: isDarkModeEnabled ? <LightModeIcon /> : <DarkModeIcon />,
    key: 'darkmode',
    onClick: () => {
      onToggleDarkMode();
      onClose();
    },
  });

  items.push({
    label: messages.shortcuts,
    type: VERTICAL_MENU_ITEM_TYPES.BUTTON,
    icon: <KeyboardIcon />,
    key: 'shortcut',
    onClick: () => {
      onOpenShortcutModal();
      onClose();
    },
  });

  items.push({
    label: messages.logout,
    type: VERTICAL_MENU_ITEM_TYPES.BUTTON,
    icon: <LogoutIcon />,
    key: 'logout',
    isLoading,
    onClick: async () => {
      await logout();
      setIsLoading(true);
    },
    dataTestId: 'logoutButton',
  });

  return (
    <>
      <ShortcutsModal
        isOpen={isShortcutsModalOpen}
        onClose={handleCloseShortcutsModal}
      />

      <Menu
        onClose={onClose}
        wrapperRef={wrapperRef}
        isOpen={isOpen}
        position={VERTICAL_MENU_POSITIONS.RIGHT}
        menuItems={items}
      />
    </>
  );
};

export default HeaderProfileMenu;
