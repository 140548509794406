import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants/src/shared';
import downloadAttachment from '@savgroup-front-common/core/src/api/Attachment/downloadAttachment';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { BackResponseFailure } from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.import}catalog/template/${COMMON_QUERY_PARAMS.SELLER_ID}`;

interface GetCatalogTemplateQueryPayload {
  sellerId: string;
}

type GetCatalogTemplateQuery = (
  payload: GetCatalogTemplateQueryPayload,
) => Promise<File | BackResponseFailure>;

export const getCatalogTemplateQuery: GetCatalogTemplateQuery = async ({
  sellerId,
}: GetCatalogTemplateQueryPayload) => {
  try {
    return await downloadAttachment({
      endpoint: buildUrl(
        ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId),
      ),
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};
